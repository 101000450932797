/*
 * @Author       : cuiguiming
 * @Date         : 2023-03-22 19:57:36
 * @LastEditors  : VOIDCUI
 * @LastEditTime : 2023-12-21 13:37:49
 * @Description  : Description
 */

import React, { useState, useRef, useEffect, useMemo } from 'react';
import qs from 'qs';
import { parseQueryDeep, stringifyQueryDeep } from './utils';
import AutoSizeText from './components/AutoSizeText';
import styled from 'styled-components/macro';
import './index.less'
const query = qs.parse(window.location.search.slice(1));
const test_url = 'https://www.baidu.com'
const CodeWrap = styled.div`
  height: 300px;
  margin: 15px 0;
`;

function renderValue(value) {
  // 增加 urlencode解码
  const reg = new RegExp('%2F');
  let result = value
  if(reg.test(result)){
	  result = decodeURIComponent(result);
  }
  return Array.isArray(result) ? `[${result.join(', ')}]` : result;
}

function renderTree(tree) {
  if (tree && tree._path && tree._query) {
    const queryKeys = Object.keys(tree._query);
    return (
      <React.Fragment>
        <span>{tree._path}</span>
        {queryKeys.length ? (
          <div>
            <strong>{` └ query: `}</strong>
          </div>
        ) : null}
        <ul>
          {queryKeys.map(qKey => {
            const qValue = tree._query[qKey];
            if (qValue._path && qValue._query) {
              return (
                <li key={qKey}>
                  <strong>{qKey + ': '}</strong>
                  {renderTree(qValue)}
                </li>
              );
            }
            return (
              <li key={qKey}>
                <strong>{qKey + ': '}</strong>
                <span>{renderValue(qValue)}</span>
              </li>
            );
          })}
        </ul>
        {tree._hash ? (
          <div>
            <strong>{` └ hash: `}</strong>
            <span>{tree._hash}</span>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
  return null;
}

const RecurseQuery = () => {
  const [err, setErr] = useState(false);
  const [url, setUrl] = useState(() => (query.debug ? test_url : ''));
  const [isEditing, setIsEditing] = React.useState(() => {
    return query.edit ? true : false;
  });
  const deepQuery = useMemo(() => {
    return parseQueryDeep(url);
  }, [url]);
  const codeRef = useRef();
  const editorRef = useRef();
  const prevCurRef = useRef();
  useEffect(() => {
    // let editor = null;
    if (isEditing) {
      if (!editorRef.current) {
        editorRef.current = window.ace.edit(codeRef.current);
        editorRef.current.setTheme('ace/theme/tomorrow_night');
        editorRef.current.session.setMode('ace/mode/json');
      }
      editorRef.current.session.setValue(JSON.stringify(deepQuery, null, 2));
      if (prevCurRef.current) {
        editorRef.current.moveCursorToPosition(prevCurRef.current);
      }

      editorRef.current.session.on('change', () => {
        let hasErr = false;
        let val = editorRef.current.session.getValue();
        try {
          val = JSON.parse(val);
          hasErr = false;
        } catch (err) {
          hasErr = true;
        }
        setErr(hasErr);
        if (!hasErr) {
          setUrl(stringifyQueryDeep(val));
        }
      });
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.session.off('change');
        prevCurRef.current = editorRef.current.getCursorPosition();
        // editorRef.current = null;
      }
    };
  }, [deepQuery, isEditing]);

  return (
    <div className="recurse-query-container">
      <h1>Recurse Query</h1>
      {/* {query.debug ? <pre>{JSON.stringify(deepQuery, null, 2)}</pre> : null} */}
      <div className="form-group">
        <label style={{color:"#2d59be",fontWeight:700}}>用来解析链接上到底带了什么东东吧～(例如:https://www.baidu.com/s?wd=sv_spt=1&id=4)</label>
        {/* <div className="float-right">
          <button
            className={`btn ${isEditing ? 'btn-secondary' : 'btn-primary'}`}
            type="button"
            onClick={() => {
              setErr(false);
              setIsEditing(is => !is);
            }}
          >
            <span>{isEditing ? 'Quit Edit' : 'Edit'}</span>
            &nbsp;
            <sup>Beta</sup>
          </button>
        </div> */}
        <AutoSizeText
          hasError={err}
          value={url}
          onChange={e => setUrl(e.target.value)}
          className="form-control"
        />
      </div>
      <div style={{ display: !isEditing ? 'none' : 'block' }}>
        <CodeWrap ref={codeRef}></CodeWrap>
      </div>
      <div style={{ display: isEditing ? 'none' : 'block' }}>
        {renderTree(deepQuery)}
      </div>
    </div>
  );
};

export default RecurseQuery;
