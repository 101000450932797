/*
 * @Author       : cuiguiming
 * @Date         : 2023-03-22 18:46:47
 * @LastEditors  : error: git config user.name & please set dead value or install git
 * @LastEditTime : 2023-03-23 10:40:53
 * @Description  : Description
 */



import React, { useEffect, useState } from 'react';
import qrcode from 'qrcode';
import qs from 'qs';
import jsqr from 'jsqr';
import './index.less';



const query = qs.parse(window.location.search.slice(1));

function isImageFile(file) {
  return /^image/.test(file.type);
}

const tempCanvas = document.createElement('canvas');
// document.body.quick-qrcode-pageend(tempCanvas);

function fileToImage(file) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = () => {
      reject(Error('file load failed', file));
    };
    img.src = URL.createObjectURL(file);
  });
}

async function fileToImageData(file) {
  const image = await fileToImage(file);
  tempCanvas.width = image.width;
  tempCanvas.height = image.height;
  const ctx = tempCanvas.getContext('2d');
  ctx.drawImage(image, 0, 0, image.width, image.height);
  return ctx.getImageData(0, 0, image.width, image.height);
}

function QuickQrcode() {
  const [text, setText] = useState(() => {
    return query.q;
  });
  const [qrcodeSrc, setQrcodeSrc] = useState();
  const [decoding, setDecoding] = useState(false);
  const [decodeData, setDecodeData] = useState();

  useEffect(() => {
    window.history.replaceState(
      null,
      '',
      qs.stringify(
        {
          ...query,
          q: text
        },
        {
          addQueryPrefix: true
        }
      )
    );
    if (text) {
      qrcode
        .toDataURL(text, {
          width: 300,
          margin: 0
        })
        .then((url) => {
          setQrcodeSrc(url);
        });
    } else {
      setQrcodeSrc(null);
    }
  }, [text]);

  async function decodeFile(file) {
    if (isImageFile(file)) {
      const imgData = await fileToImageData(file);
      const code = jsqr(imgData.data, imgData.width, imgData.height);
      if (code) {
        setDecodeData({
          hasData: true,
          data: code.data
        });
      } else {
        setDecodeData({
          hasData: false
        });
      }
    } else {
      alert('只能是图片文件');
    }
  }

  return (
    <div className="quick-qrcode-page">
      <h1>Quick Qrcode</h1>
      <h4 className="quick-qrcode-page-label">输入需要转成二维码的链接: </h4>
      <textarea
        rows="3"
        className="quick-qrcode-page-input"
        value={text}
		placeholder="请输入链接"
        onChange={(e) => {
          const v = e.target.value;
          setText(v);
        }}
      />
      <div className="quick-qrcode-page-qrcode">
        {qrcodeSrc && <img src={qrcodeSrc} alt="" />}
      </div>
      <hr style={{margin:'50px 0',backgroundColor: '#83cdf8',height:'2px', border:"none"}}/>
      <h1>Quick Decode</h1>
	  <h4>上传需要识别的二维码图片</h4>
      <div className="file-picker">
        <div className="file-picker-before">
          {decoding ? 'decoding...' : 'choose or drop file'}
        </div>
        <input
          type="file"
          className="file-picker-control"
          onChange={async (e) => {
            if (decoding) {
              return;
            }
            setDecoding(true);
            setDecodeData();
            const [file] = e.target.files;
            e.target.value = '';
            await decodeFile(file);
            setDecoding(false);
          }}
        />
      </div>
      <br />
      <p>从剪切板中解析：</p>
      <input
        className="quick-qrcode-page-input"
        placeholder="decode from clip borad"
        type="text"
        onPaste={async (e) => {
          setDecoding(true);
          setDecodeData();
          const [file] = e.clipboardData.files;
          if (file) {
            await decodeFile(file);
          }
          setDecoding(false);
        }}
      />
      {decodeData ? (
        decodeData.hasData ? (
          <div>
            <p>decode text: </p>
            <p>{decodeData.data}</p>
          </div>
        ) : (
          <div>
            <p>未找到二维码，可尝试裁剪图片后再试</p>
          </div>
        )
      ) : null}
    </div>
  );
}



export default QuickQrcode;