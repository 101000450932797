/*
 * @Author       : cuiguiming
 * @Date         : 2023-03-22 18:37:45
 * @LastEditors  : error: git config user.name & please set dead value or install git
 * @LastEditTime : 2023-03-22 18:37:46
 * @Description  : Description
 */


const Home = () => {
	return <div>Home</div>
}

export default Home;