import React, { useRef, useEffect } from 'react';
import styled from 'styled-components/macro';

const Area = styled.textarea`
  width: 100%;
  resize: none;
  overflow: hidden;
  margin: 30px 0;
  height: 60px;
`;

const AutoSizeText = React.forwardRef((props, ref) => {
  const areaRef = useRef();
  useEffect(() => {
    const $area = areaRef.current;
    $area.style.height = 'auto';
    $area.style.height = $area.scrollHeight + 'px';
  }, [props.value]);
  return (
    <Area
      style={{
        ...props.style,
        border: props.hasError ? '1px solid red' : undefined
      }}
	  placeholder="请输入你需要解析的链接"
      ref={elem => {
        areaRef.current = elem;
        if (ref) {
          ref.current = elem;
        }
      }}
      className="form-control"
      value={props.value}
      onChange={props.onChange}
    />
  );
});

export default AutoSizeText;
