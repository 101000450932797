/*
 * @Author       : cuiguiming
 * @Date         : 2023-03-22 19:58:07
 * @LastEditors  : VOIDCUI
 * @LastEditTime : 2023-12-21 10:14:01
 * @Description  : Description
 */
const qs = require('qs');
const urlParse = require('url-parse');
const omit = require('ramda/src/omit');

function isUrl(str) {
  const pUrl = urlParse(str, '/');
  return Boolean(pUrl.protocol && pUrl.origin);
}

function findUrlPair(query) {
  return Object.keys(query).reduce((acc, k) => {
    if (!acc.key && isUrl(query[k])) {
      acc.key = k;
      acc.value = query[k];
      return acc;
    }
    return acc;
  }, {});
}

function reserveKeyOrder(origin, next) {
  if (!origin) {
    return {};
  }
  return Object.keys(origin).reduce((acc, k) => {
    acc[k] = next[k];
    return acc;
  }, {});
}

export function parseQueryDeep(url) {
  // console.log(url);
  if (!url) {
    return {};
  }
  const pUrl = urlParse(url, '/');
  const path = pUrl.origin + pUrl.pathname;

  if (!pUrl.origin) {
    return {};
  }

  const query = qs.parse(pUrl.query, { ignoreQueryPrefix: true });
  const urlPair = findUrlPair(query);

  const next = urlPair.key
    ? { [urlPair.key]: parseQueryDeep(urlPair.value) }
    : {};

  return {
    _path: path,
    _query: reserveKeyOrder(query, {
      ...omit([urlPair.key], query),
      ...next
    }),
    _hash: pUrl.hash
  };
}

function findNestedUrl(query) {
  if (!query) {
    return {};
  }
  return Object.keys(query).reduce((acc, k) => {
    if (!acc.key && query[k]._path && query[k]._query) {
      acc.key = k;
      acc.value = query[k];
    }
    return acc;
  }, {});
}

export function stringifyQueryDeep(url) {
  let { _path, _query, _hash } = url;
  const nestedUrl = findNestedUrl(_query);
  const next = nestedUrl.key
    ? { [nestedUrl.key]: stringifyQueryDeep(nestedUrl.value) }
    : {};
  return (
    (_path ? _path : '') +
    qs.stringify(
      {
        ...reserveKeyOrder(_query, {
          ...omit([nestedUrl.key], _query),
          ...next
        })
      },
      { addQueryPrefix: true }
    ) +
    (_hash ? _hash : '')
  );
}
