/*
 * @Author       : cuiguiming
 * @Date         : 2023-07-26 17:00:58
 * @LastEditors  : VOIDCUI
 * @LastEditTime : 2023-07-26 17:53:30
 * @Description  : Description
 */

const dataList = [
  {
    title: "Quick Qrcode",
    desc: "用于生成二维码",
    author: "cui",
    type: "vue",
    path: "/quick-qrcode",
  },
  {
    title: "Recurse Query",
    desc: "用于解析链接上的链接参数",
    author: "cui",
    type: "react",
    path: "/recurse-query",
  },
//   {
//     title: "ChatGpt",
//     desc: "智能聊天工具",
//     author: "cui",
//     type: "react",
//     herf: "https://chat.voidcui.top",
//   },
	{
    title: "DM",
    desc: "用于生成大麦bq链接和二维码",
    author: "cui",
    type: "react",
    path: "/dm",
  },
];


export default dataList;