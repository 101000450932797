/*
 * @Author       : cuiguiming
 * @Date         : 2023-07-26 17:53:51
 * @LastEditors  : VOIDCUI
 * @LastEditTime : 2023-08-22 18:33:00
 * @Description  : Description
 */

import React, { useEffect, useState,  useCallback } from "react";
import qrcode from "qrcode";
import qs from "qs";
import "./index.less";
const query = qs.parse(window.location.search.slice(1));

const Dm = () => {
  const [text, setText] = useState(() => {
    return query.q;
  });
  const [bpUrl,setBpUrl] = useState('');
  const [qrcodeSrc, setQrcodeSrc] = useState();
  const shareToBp = useCallback((text) => {
	if(!text) return '';
	// https://m.damai.cn/damai/detail/item.html?utm=&itemId=727473084348 匹配itemId出来
	const match = text.match(/itemId=(\d+)/);
	// const match = text.match(/https:\/\/m\.damai\.cn\/(.*?)\.html/);
	if(match){
		// const skuUrl = `https://m.damai.cn/damai/detail/sku.html?id=${match[1]}`;
		const url = `https://pages.damai.cn/landing?to=wxapp&url=pages%2Fsku%2Findex%3Fid%3D${match[1]}`;
		// const url  = `https://login.m.taobao.com/mini_login.htm?lang=zh_cn&appEntrance=sns_bind&appName=taobao&returnUrl=${encodeURIComponent(skuUrl)}`
		return url;
	}
	return '';
  },[])




  useEffect(() => {
    if (text) {
	  const bpUrl = shareToBp(text);
      qrcode
        .toDataURL(bpUrl, {
          width: 300,
          margin: 0,
        })
        .then((url) => {
		  setBpUrl(bpUrl);
          setQrcodeSrc(url);
        });
    } else {
      setQrcodeSrc(null);
    }
  }, [shareToBp, text]);


  return (
    <div className="quick-qrcode-page">
      <h1>用于生成大麦bq链接和二维码</h1>
      <h4 className="quick-qrcode-page-label">填入大麦分享链接: </h4>
	  
      <textarea
        rows="3"
        className="quick-qrcode-page-input"
        value={text}
        placeholder="请输入链接"
        onChange={(e) => {
          const v = e.target.value;
          setText(v);
        }}
      />
      <h4 className="title" >转码为大麦bp小程序链接：<span>{bpUrl}</span></h4>
	  <br/>
      <h4 className="quick-qrcode-page-label">大麦bp二维码（请使用微信扫）：</h4>
      <div className="quick-qrcode-page-qrcode">
        {qrcodeSrc && <img src={qrcodeSrc} alt="" />}
      </div>
      <hr
        style={{
          margin: "50px 0",
          backgroundColor: "#83cdf8",
          height: "2px",
          border: "none",
        }}
      />
      <br />
    </div>
  );
};

export default Dm;
